.clear-button {
  margin-left: 1em;
  background-color: #db3e30;
  color: #fff;
  padding: 0.8em;
  cursor: pointer;
  font-size: 1.5em;
  border: none;
  border-radius: 2em;
  box-shadow: 1px 1px 8px 2px #95a5a6;
}

.video {
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(00, 00, 00, 0.6);
  z-index: 20;
  display: grid;
  place-items: center;
}

.footer {
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  padding: 20px;
}
